import React from "react";
import moment, { duration } from "moment";
import ReactMarkdown from "react-markdown";

import { GetCandidateInfoResponse, GetRecrutationInfoResponse } from "@recrutation/candidate-api-types";
import { formatDuration, formatSeconds } from "../utils/Time";

interface CandidateInfoProps {
    candidateInfo: GetCandidateInfoResponse;
    recrutationInfo: GetRecrutationInfoResponse;
    currentTimestamp: number;
    startRecrutation: () => void;
}

const CandidateInfo: React.FC<CandidateInfoProps> = props => {
    const endTime = moment(props.candidateInfo.Candidate.StartDate).add(
        props.recrutationInfo.Recrutation.TotalTimeInSeconds,
        "seconds"
    );

    return (
        <div className="_candidate-info">
            <div className="_candidate-info__recrutation-name">{props.recrutationInfo.Recrutation.Label}</div>
            <div className="_candidate-info__title">Witaj {props.candidateInfo.Candidate.FullName}</div>
            {!!props.candidateInfo.Candidate.StartDate ? (
                !props.candidateInfo.Candidate.EndDate &&
                endTime.isAfter(moment.unix(props.candidateInfo.ServerTimestamp)) ? (
                    <div className="_candidate-info__state">
                        Twoja rekrutacja właśnie trwa.
                        <br />
                        Pozostało{" "}
                        {formatDuration(
                            duration(
                                moment(props.candidateInfo.Candidate.StartDate)
                                    .add(props.recrutationInfo.Recrutation.TotalTimeInSeconds, "seconds")
                                    .diff(moment.unix(props.currentTimestamp))
                            )
                        )}
                        <br />
                        <br />
                        <button className="_canidate-info__state-button" onClick={() => props.startRecrutation()}>
                            Zacznij od początku
                        </button>
                    </div>
                ) : (
                    <div className="_candidate-info__state">Twoja rekrutacja została zakończona.</div>
                )
            ) : (
                <div className="_candidate-info__state">
                    <ReactMarkdown source={props.recrutationInfo.Recrutation.About} escapeHtml={true} />
                    <br />
                    <br />
                    <button className="_candidate-info__state-button" onClick={() => props.startRecrutation()}>
                        Start
                    </button>
                </div>
            )}
        </div>
    );
};

export default CandidateInfo;
