import React from "react";
import { GetRecrutationInfoResponse } from "@recrutation/candidate-api-types";

interface TypeSecretCodeProps {
    recrutationInfo: GetRecrutationInfoResponse;
    secretCode: string;
    setSecretCodeValue: (value: string) => void;
    checkSecretCode: () => void;
}

const TypeSecretCode: React.FC<TypeSecretCodeProps> = props => {
    const handleKeyPress = (key: React.KeyboardEvent<HTMLInputElement>) => {
        if (key.key === "Enter") {
            props.checkSecretCode();
        }
    };

    return (
        <div className="_type-secret-code">
            <div className="_type-secret-code__recrutation-name">{props.recrutationInfo.Recrutation.Label}</div>
            <div className="_type-secret-code__title">Wpisz swój kod kandydata</div>
            <div className="_type-secret-code__field">
                <input
                    className="_type-secret-code__field-input"
                    type="text"
                    value={props.secretCode}
                    onChange={e => props.setSecretCodeValue(e.target.value)}
                    onKeyDown={e => handleKeyPress(e)}
                />
            </div>
            <div className="_type-secret-code__check">
                <button className="_type-secret-code__check-button" onClick={() => props.checkSecretCode()}>
                    Zatwierdź
                </button>
            </div>
        </div>
    );
};

export default TypeSecretCode;
