import React from "react";

import {
    RecrutationQuestion,
    PostStartRecrutationData,
    PostStartRecrutationResponse
} from "@recrutation/candidate-api-types";
import moment, { Duration, duration } from "moment";
import { formatDuration } from "../utils/Time";

interface QuestionProps {
    startRecrutation: PostStartRecrutationResponse;
    question: RecrutationQuestion;
    count: number;
    countTotal: number;
    questionAnswers: number[];
    currentTimestamp: number;
    questionStartTimestamp: number | undefined;
    changeAnswerState: (answerId: number) => void;
    nextQuestion: () => void;
}

const Question: React.FC<QuestionProps> = props => {
    const questionLeftSeconds: Duration | undefined =
        props.question.TotalTimeInSeconds > 0 && !!props.questionStartTimestamp
            ? duration(
                  moment
                      .unix(props.questionStartTimestamp)
                      .add(props.question.TotalTimeInSeconds, "seconds")
                      .diff(moment.unix(props.currentTimestamp))
              )
            : undefined;

    const recrutationLeftSeconds: Duration = duration(
        moment.unix(props.startRecrutation.EndBeforeTimestamp).diff(moment.unix(props.currentTimestamp))
    );

    return (
        <div className="_question">
            <div className="_question__timer-global">
                Pozostało do końca testu:
                <br />
                <b> {formatDuration(recrutationLeftSeconds)}</b>
            </div>
            {questionLeftSeconds === undefined ? null : (
                <div className="_question__timer">
                    Czas na to pytanie:
                    <br />
                    <b> {formatDuration(questionLeftSeconds)}</b>
                </div>
            )}
            <div className="_question__title">
                Pytanie {props.count + 1} / {props.countTotal}
            </div>
            <div className="_question__question">{props.question.Question}</div>
            <div className="_question__answers">
                {props.question.Answers.map(answer => {
                    const isChecked = props.questionAnswers.includes(answer.AnswerId);
                    return (
                        <div
                            className={`_question__answer ${
                                props.questionAnswers.includes(answer.AnswerId) ? "is-check" : ""
                            }`}
                            onClick={() => {
                                console.log("click: " + answer.AnswerId);
                                props.changeAnswerState(answer.AnswerId);
                            }}
                            key={answer.AnswerId}
                        >
                            <div className="_question__answer-icon">{isChecked ? "check" : ""}</div>
                            <div className="_question__answer-text">{answer.Answer}</div>
                        </div>
                    );
                })}
            </div>
            <div className="_question__buttons">
                <button className="_question__button" onClick={() => props.nextQuestion()}>
                    Dalej
                </button>
            </div>
        </div>
    );
};

export default Question;
