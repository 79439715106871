import React from "react";
import ReactDOM from "react-dom";

import "./scss/index.scss";

import App from "./App";

declare global {
    const APP_CONFIG: {
        API_URL: string;
    };
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);
