import React from "react";

export interface OverlayProps {
    show?: boolean;
    title?: string;
    description?: string;
    className?: string;
}

const Overlay: React.FC<OverlayProps> = props => {
    if (!props.show) {
        return null;
    }
    return (
        <div className={`overlay ${props.className || ""}`}>
            {!!props.title ? <div className="overlay__title">{props.title}</div> : null}
            {!!props.description ? <div className="overlay__description">{props.description}</div> : null}
            {props.children}
        </div>
    );
};

export default Overlay;
