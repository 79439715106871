import React from "react";

import Recrutation from "./areas/Recrutation";

export default class App extends React.Component {
    render() {
        return (
            <div className="app">
                <Recrutation />
            </div>
        );
    }
}
