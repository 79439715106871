import axios, { AxiosResponse } from "axios";
import moment from "moment";
import React from "react";

import {
    GetRecrutationInfoResponse,
    GetCandidateInfoResponse,
    PostStartRecrutationResponse,
    PostEndRecrutationResponse,
    GetRecrutationInfoParams
} from "@recrutation/candidate-api-types";
import {
    API_ENDPOINTS,
    GetCandidateInfoParams,
    PostStartRecrutationData,
    PostEndRecrutationData
} from "@recrutation/candidate-api-types/dist/api/index";
import { Endpoint, EndpointIdleState } from "../utils/Endpoint";
import Overlay, { OverlayProps } from "../components/Overlay";

import CandidateInfo from "./CanidateInfo";
import TypeSecretCode from "./TypeSecretCode";
import Question from "./Question";

interface RecrutationState {
    endpoints: {
        GetRecrutationInfo: Endpoint<GetRecrutationInfoResponse>;
        GetCandidateInfo: Endpoint<GetCandidateInfoResponse>;
        StartRecrutation: Endpoint<PostStartRecrutationResponse>;
        EndRecrutation: Endpoint<PostEndRecrutationResponse>;
    };
    RecrutationGuid: string | undefined;
    SecretCode: string;
    CurrentTimestamp: number;
    CurrentQuestionIndex: number | undefined;
    CurrentQuestionTimeStart: number | undefined;
    QuestionAnswers: number[];
}

export default class Recrutation extends React.Component<{}, RecrutationState> {
    state: RecrutationState = {
        endpoints: {
            GetRecrutationInfo: EndpointIdleState,
            GetCandidateInfo: EndpointIdleState,
            StartRecrutation: EndpointIdleState,
            EndRecrutation: EndpointIdleState
        },
        RecrutationGuid: undefined,
        SecretCode: "",
        CurrentTimestamp: moment().unix(),
        CurrentQuestionIndex: undefined,
        CurrentQuestionTimeStart: undefined,
        QuestionAnswers: []
    };

    private timestampInterval: number | undefined;
    private questionTimeout: number | undefined;

    componentDidMount() {
        const params = new URLSearchParams(window.location.search);
        this.setState(
            { RecrutationGuid: params.get("RecrutationGuid") || undefined },
            () => this.getRecrutationInfo()
        );

        this.timestampInterval = window.setInterval(
            () => this.setState({ CurrentTimestamp: moment().unix() }),
            1000
        );
    }

    componentWillUnmount() {
        if (!!this.timestampInterval) {
            window.clearInterval(this.timestampInterval);
        }
    }

    render() {
        if (!this.state.RecrutationGuid) {
            return (
                <div className="_recrutation">
                    <div className="_recrutation__warning">
                        <div className="_recrutation__warning-title">
                            Uszkodzony link
                        </div>
                        <div className="_recrutation__warning-description">
                            Otwarty link nie zawiera informacji o rekrutacji w
                            której chcesz uczestniczyć. Sprawdź poprawność
                            linku.
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.endpoints.GetRecrutationInfo.state === "pending") {
            return (
                <div className="_recrutation">
                    <div className="_recrutation__info">
                        <div className="_recrutation__info-title">
                            Pobieranie informacji o rekrutacji...
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.endpoints.StartRecrutation.state === "pending") {
            return (
                <div className="_recrutation">
                    <div className="_recrutation__info">
                        <div className="_recrutation__info-title">
                            Rozpoczynanie testu...
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.endpoints.StartRecrutation.state === "error") {
            return (
                <div className="_recrutation">
                    <div className="_recrutation__warning">
                        <div className="_recrutation__warning-title">
                            Wystąpił błąd
                        </div>
                        <div className="_recrutation__warning-description">
                            Wystąpił błąd podczas rozpoczynania rekrutacji.
                            <br />
                            Błąd: {this.state.endpoints.StartRecrutation.error}
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.endpoints.EndRecrutation.state === "pending") {
            return (
                <div className="_recrutation">
                    <div className="_recrutation__info">
                        <div className="_recrutation__info-title">
                            Wysyłanie odpowiedzi...
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.endpoints.EndRecrutation.state === "completed") {
            return (
                <div className="_recrutation">
                    <div className="_recrutation__info">
                        <div className="_recrutation__info-title">
                            <b>
                                {
                                    this.state.endpoints.GetRecrutationInfo
                                        .response?.Recrutation.Label
                                }
                            </b>
                            <br />
                            <br />
                            Test zakończony. <br />
                            Dziękujemy za udział w rekrutacji.<br />
                            <br />
                            Wynik: <b>{(this.state.endpoints.EndRecrutation.response.Score * 100).toFixed(2)} %</b>
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.endpoints.EndRecrutation.state === "error") {
            return (
                <div className="_recrutation">
                    <div className="_recrutation__warning">
                        <div className="_recrutation__warning-title">
                            Wystąpił błąd
                        </div>
                        <div className="_recrutation__warning-description">
                            Podczas wysyłania odpowiedzi nastąpił błąd.
                            <br />
                            Błąd: {this.state.endpoints.EndRecrutation.error}
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.endpoints.GetRecrutationInfo.state === "completed") {
            return (
                <div className="_recrutation">
                    {this.state.endpoints.GetCandidateInfo.state !==
                    "completed" ? (
                        <TypeSecretCode
                            recrutationInfo={
                                this.state.endpoints.GetRecrutationInfo.response
                            }
                            secretCode={this.state.SecretCode}
                            setSecretCodeValue={(secretCode) =>
                                this.setState({ SecretCode: secretCode })
                            }
                            checkSecretCode={() => this.getCandidateInfo()}
                        />
                    ) : null}
                    {this.state.endpoints.GetCandidateInfo.state ===
                        "completed" &&
                    this.state.endpoints.StartRecrutation.state !==
                        "completed" ? (
                        <CandidateInfo
                            candidateInfo={
                                this.state.endpoints.GetCandidateInfo.response
                            }
                            recrutationInfo={
                                this.state.endpoints.GetRecrutationInfo.response
                            }
                            currentTimestamp={this.state.CurrentTimestamp}
                            startRecrutation={() => this.startRecrutation()}
                        />
                    ) : null}
                    {this.state.endpoints.StartRecrutation.state ===
                        "completed" &&
                    this.state.CurrentQuestionIndex !== undefined ? (
                        <Question
                            question={
                                this.state.endpoints.StartRecrutation.response
                                    .Questions[this.state.CurrentQuestionIndex]
                            }
                            count={this.state.CurrentQuestionIndex}
                            countTotal={
                                this.state.endpoints.StartRecrutation.response
                                    .Questions.length
                            }
                            questionAnswers={this.state.QuestionAnswers}
                            changeAnswerState={(answerId) =>
                                this.changeAnswerId(answerId)
                            }
                            currentTimestamp={this.state.CurrentTimestamp}
                            questionStartTimestamp={
                                this.state.CurrentQuestionTimeStart
                            }
                            nextQuestion={() => this.nextQuestion()}
                            startRecrutation={
                                this.state.endpoints.StartRecrutation.response
                            }
                        />
                    ) : null}
                    <Overlay {...this.overlayProps} />
                </div>
            );
        }

        return (
            <div className="_recrutation">
                <Overlay {...this.overlayProps} />
            </div>
        );
    }

    get overlayProps(): React.PropsWithChildren<OverlayProps> {
        if (this.state.endpoints.GetRecrutationInfo.state === "error") {
            return {
                show: true,
                title:
                    "Wystąpił błąd podczas pobierania informacji o rekrutacji.",
                description: `Błąd: ${this.state.endpoints.GetRecrutationInfo.error}`
            };
        }
        if (this.state.endpoints.GetCandidateInfo.state === "pending") {
            return {
                show: true,
                title: "Ładowanie danych kandydata..."
            };
        }
        if (this.state.endpoints.GetCandidateInfo.state === "error") {
            return {
                show: true,
                title: "Wystąpił błąd podczas ładowania danych kandydata",
                description: `Błąd: ${this.state.endpoints.GetCandidateInfo.error}`,
                children: (
                    <div className="overlay__children">
                        <button
                            className="overlay__children-button"
                            onClick={() => this.resetSecretCode()}
                        >
                            Ok
                        </button>
                    </div>
                )
            };
        }
        return { show: false };
    }

    private changeAnswerId(answerId: number) {
        this.setState((p) => {
            const QuestionAnswers = [...p.QuestionAnswers];

            const index = QuestionAnswers.indexOf(answerId);

            if (index === -1) {
                QuestionAnswers.push(answerId);
            } else {
                QuestionAnswers.splice(index, 1);
            }

            return { QuestionAnswers: QuestionAnswers };
        });
    }

    private resetSecretCode() {
        this.setState({ SecretCode: "" }, () =>
            this.updateEndpoint("GetCandidateInfo", {
                state: "idle",
                response: undefined,
                error: undefined
            })
        );
    }

    private async updateEndpoint<
        TEndpoint extends keyof RecrutationState["endpoints"]
    >(
        endpoint: TEndpoint,
        state: RecrutationState["endpoints"][TEndpoint]
    ): Promise<void> {
        return new Promise((resolve) =>
            this.setState(
                (p) => ({ endpoints: { ...p.endpoints, [endpoint]: state } }),
                () => resolve()
            )
        );
    }

    private async getRecrutationInfo() {
        if (!this.state.RecrutationGuid) {
            return;
        }
        try {
            const params: GetRecrutationInfoParams = {
                RecrutationGuid: this.state.RecrutationGuid
            };
            this.updateEndpoint("GetRecrutationInfo", {
                state: "pending",
                response: undefined,
                error: undefined
            });
            const response: AxiosResponse<GetRecrutationInfoResponse> = await axios(
                {
                    url:
                        APP_CONFIG.API_URL +
                        API_ENDPOINTS.GetRecrutationInfoUrl,
                    method: "GET",
                    params: params
                }
            );
            this.updateEndpoint("GetRecrutationInfo", {
                state: "completed",
                response: response.data,
                error: undefined
            });
        } catch (e) {
            if (
                !!e.response &&
                !!e.response.data &&
                typeof e.response.data === "string"
            ) {
                this.updateEndpoint("GetRecrutationInfo", {
                    state: "error",
                    response: undefined,
                    error: e.response.data
                });
            } else {
                this.updateEndpoint("GetRecrutationInfo", {
                    state: "error",
                    response: undefined,
                    error: "Błąd serwera."
                });
            }
        }
    }

    private async getCandidateInfo() {
        if (!this.state.RecrutationGuid || !this.state.SecretCode) {
            return;
        }
        try {
            const params: GetCandidateInfoParams = {
                RecrutationGuid: this.state.RecrutationGuid,
                SecretCode: this.state.SecretCode
            };
            this.updateEndpoint("GetCandidateInfo", {
                state: "pending",
                response: undefined,
                error: undefined
            });
            const response: AxiosResponse<GetCandidateInfoResponse> = await axios(
                {
                    url: APP_CONFIG.API_URL + API_ENDPOINTS.GetCandidateInfoUrl,
                    method: "GET",
                    params: params
                }
            );

            await this.updateEndpoint("GetCandidateInfo", {
                state: "completed",
                response: response.data,
                error: undefined
            });

            if (
                !!response.data.Candidate.StartDate &&
                !response.data.Candidate.EndDate
            ) {
                this.startRecrutation();
            }
        } catch (e) {
            if (
                !!e.response &&
                !!e.response.data &&
                typeof e.response.data === "string"
            ) {
                this.updateEndpoint("GetCandidateInfo", {
                    state: "error",
                    response: undefined,
                    error: e.response.data
                });
            } else {
                this.updateEndpoint("GetCandidateInfo", {
                    state: "error",
                    response: undefined,
                    error: "Błąd serwera."
                });
            }
        }
    }

    private async startRecrutation() {
        if (!this.state.RecrutationGuid || !this.state.SecretCode) {
            return;
        }
        try {
            const data: PostStartRecrutationData = {
                RecrutationGuid: this.state.RecrutationGuid,
                SecretCode: this.state.SecretCode
            };
            this.updateEndpoint("StartRecrutation", {
                state: "pending",
                response: undefined,
                error: undefined
            });
            const response: AxiosResponse<PostStartRecrutationResponse> = await axios(
                {
                    url:
                        APP_CONFIG.API_URL +
                        API_ENDPOINTS.PostStartRecrutationUrl,
                    method: "POST",
                    data: data
                }
            );
            await this.updateEndpoint("StartRecrutation", {
                state: "completed",
                response: response.data,
                error: undefined
            });
            this.nextQuestion();
        } catch (e) {
            if (
                !!e.response &&
                !!e.response.data &&
                typeof e.response.data === "string"
            ) {
                this.updateEndpoint("StartRecrutation", {
                    state: "error",
                    response: undefined,
                    error: e.response.data
                });
            } else {
                this.updateEndpoint("StartRecrutation", {
                    state: "error",
                    response: undefined,
                    error: "Błąd serwera."
                });
            }
        }
    }

    private async endRecrutation() {
        if (!this.state.RecrutationGuid || !this.state.SecretCode) {
            return;
        }

        try {
            const data: PostEndRecrutationData = {
                RecrutationGuid: this.state.RecrutationGuid,
                SecretCode: this.state.SecretCode,
                QuestionAnswers: this.state.QuestionAnswers
            };
            this.updateEndpoint("EndRecrutation", {
                state: "pending",
                response: undefined,
                error: undefined
            });
            const response:AxiosResponse<PostEndRecrutationResponse> = await axios({
                url: APP_CONFIG.API_URL + API_ENDPOINTS.PostEndRecrutationUrl,
                method: "POST",
                data: data
            });
            this.updateEndpoint("EndRecrutation", {
                state: "completed",
                response: response.data,
                error: undefined
            });
        } catch (e) {
            if (
                !!e.response &&
                !!e.response.data &&
                typeof e.response.data === "string"
            ) {
                this.updateEndpoint("EndRecrutation", {
                    state: "error",
                    response: undefined,
                    error: e.response.data
                });
            } else {
                this.updateEndpoint("EndRecrutation", {
                    state: "error",
                    response: undefined,
                    error: "Błąd serwera."
                });
            }
        }
    }

    private async nextQuestion() {
        if (this.state.endpoints.StartRecrutation.state !== "completed") {
            return;
        }
        let nextIndex =
            this.state.CurrentQuestionIndex === undefined
                ? 0
                : this.state.CurrentQuestionIndex + 1;

        if (!!this.questionTimeout) {
            window.clearTimeout(this.questionTimeout);
        }

        if (
            nextIndex >=
            this.state.endpoints.StartRecrutation.response.Questions.length
        ) {
            this.endRecrutation();

            return;
        }

        const question = this.state.endpoints.StartRecrutation.response
            .Questions[nextIndex];

        this.setState(
            {
                CurrentQuestionIndex: nextIndex,
                CurrentQuestionTimeStart: moment().unix()
            },
            () => {
                if (question.TotalTimeInSeconds > 0) {
                    this.questionTimeout = window.setTimeout(
                        () => this.nextQuestion(),
                        question.TotalTimeInSeconds * 1000
                    );
                }
            }
        );
    }
}
