export type EndpointState = "idle" | "pending" | "completed" | "error";

export interface EndpointIdle {
    response: undefined;
    state: "idle";
    error: undefined;
}

export interface EndpointPending {
    response: undefined;
    state: "pending";
    error: undefined;
}

export interface EndpointCompleted<TResponse> {
    response: TResponse;
    state: "completed";
    error: undefined;
}

export interface EndpointError {
    response: undefined;
    state: "error";
    error: string;
}

export type Endpoint<TResponse = undefined> =
    | EndpointIdle
    | EndpointPending
    | EndpointCompleted<TResponse>
    | EndpointError;

export const EndpointIdleState: Endpoint<any> = {
    response: undefined,
    state: "idle",
    error: undefined
};
