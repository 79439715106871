import { Duration, duration } from "moment";

export function formatDuration(d: Duration): string {
    let str = "";

    if (d.asSeconds() < 0) {
        d = duration(0);
    }
    if (d.asHours() >= 1) {
        str += `${d.hours()} h`;
    }
    str += `${d.minutes()} m ${d.seconds()} s`;
    return str;
}

export function formatSeconds(d: number): string {
    return formatDuration(duration(d, "seconds"));
}
